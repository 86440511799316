.main-container {
  width: 550px;
  margin: 0 auto;
  font-size: 1rem;
  text-align: center;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@media only screen and (min-width: 1250px) {
  .main-container {
    width: 700px;
  }
}

@media only screen and (max-width: 550px) {
  .main-container {
    width: 100%;
  }
}
