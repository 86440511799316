.button {
  background: #ff0000;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  padding: 14px 25px;
  cursor: pointer;
  outline: none;
  transition-duration: 500ms;
  transition-property: background, color, opacity;

  &:hover {
    // color: var(--color-white);
    // background: var(--color-black);
    opacity: var(--button-hover-opacity);
  }
}

@media only screen and (min-width: 1250px) {
  .button {
    padding: 16px 30px;
  }
}
