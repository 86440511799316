.response-section {
  font-family: 'Cabin', sans-serif;
  font-size: 0.89rem;
  margin-top: 5px;
  margin-bottom: 60px;

  i {
    font-size: 3.2rem;

    &.success {
      color: #00b300;
    }

    &.alert {
      color: red;
    }

    &.error {
      color: #ffea00;
    }
  }

  .response-text {
    margin-top: 15px;

    p {
      color: var(--response-color);
    }
  }

  .response-new-search {
    margin-top: 15px;
    text-align: center;
    position: relative;

    a {
      text-decoration: none;
      color: var(--response-color);
      transition-duration: 500ms;
      transition-property: opacity;

      &:hover {
        text-decoration: none;
        opacity: 0.8;
      }
    }

    i {
      font-size: 0.89rem;
      color: #d3d3d3;
      cursor: pointer;
      transition-duration: 500ms;
      transition-property: opacity, color;

      &:hover {
        opacity: 0.8;
        color: red;
      }
    }
  }
}

@media only screen and (min-width: 1250px) {
  .response-section {
    font-size: 1.1rem;

    i {
      font-size: 3.7rem;
    }

    .response-new-search {
      i {
        font-size: 1.1rem;
      }
    }
  }
}
