.tagline p {
  font-size: 1rem;
}

@media only screen and (min-width: 1250px) {
  .tagline p {
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 395px) {
  .tagline p {
    font-size: 0.89rem;
  }
}

@media only screen and (max-width: 345px) {
  .tagline p {
    font-size: 0.79rem;
  }
}
