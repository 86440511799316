.empty-submit {
  position: absolute;
  bottom: -36%;
  left: 36%;

  p {
    color: var(--empty-submit-color);
  }
}

@media only screen and (min-width: 1250px) {
  .empty-submit {
    left: 39%;
  }
}

@media only screen and (max-width: 465px) {
  .empty-submit {
    left: 32%;
  }
}

@media only screen and (max-width: 407px) {
  .empty-submit {
    left: 31%;
  }
}

@media only screen and (max-width: 380px) {
  .empty-submit {
    left: 30%;
  }
}

@media only screen and (max-width: 340px) {
  .empty-submit {
    left: 28%;
  }
}

@media only screen and (max-width: 320px) {
  .empty-submit {
    left: 27%;
  }
}
