.disclaimer-item {
  background-color: #fff;
  margin: auto;
  margin-top: 20vh;
  padding: 40px 20px;
  width: 450px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  position: relative;

  p {
    color: #808080;
    line-height: 1.5;
    text-align: center;
    margin: auto;
    width: 90%;
    opacity: 0.8;
  }

  .disclaimer-bold {
    color: black;
    font-weight: 700;
  }

  .window-close {
    position: absolute;
    right: 4%;
    top: 8%;
    cursor: pointer;

    &:hover {
      color: black;
    }

    &:active {
      color: #808080;
    }
  }

  h3 {
    color: black;
    font-weight: bold;
  }

  .disclaimer-item-linebreak {
    border-bottom: 2px solid black;
    margin: 5px auto;
    width: 3%;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 500px) {
  .disclaimer-item {
    width: 90%;
  }
}

@media only screen and (max-width: 500px) {
  .disclaimer-item {
    width: 70%;
  }
}
