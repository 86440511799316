.loading-spin {
  position: relative;
  margin-bottom: 60px;

  p {
    font-family: 'Cabin', sans-serif;
    font-size: 0.89rem;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  div {
    svg {
      position: absolute;
      margin-top: 15px;
      width: 50px;
      height: auto;
      left: 44.8%;
    }
  }
}

@media only screen and (min-width: 1250px) {
  .loading-spin {
    p {
      font-size: 1.1rem;
      margin-top: 35px;
      margin-bottom: 20px;
    }

    div {
      svg {
        margin-top: 15px;
        width: 60px;
        left: 45.5%;
      }
    }
  }
}
