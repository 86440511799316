footer {
  margin-top: 150px;
  span {
    // color: var(--p-color);
    font-size: 0.7rem;
    padding-bottom: 10px;
    color: var(--footer-color);

    a {
      color: var(--p-color);
      font-weight: bold;
      text-decoration: none;

      &:hover {
        color: black;
      }
    }
  }
}

@media only screen and (min-width: 1250px) {
  footer {
    margin-top: 180px;
    span {
      font-size: 1rem;
    }
  }
}
