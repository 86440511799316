header {
  h1 {
    margin: 0;
    padding: 0;
    font-size: 2.5rem;

    img {
      max-width: 100%;
      height: auto;
    }

    .logo-left {
      color: #ff0000;
      font-weight: 900;
    }

    .logo-right {
      font-weight: 900;
      transition-duration: 500ms;
      transition-property: color;
      color: var(--color-black);
    }
  }
}

@media only screen and (min-width: 1250px) {
  header {
    h1 {
      margin: 0;
      padding: 0;
      font-size: 3rem;
    }
  }
}
