.theme {
  margin-top: 10px;
  i {
    color: #bfbfbf;
    cursor: pointer;
    transition-duration: 500ms;
    transition-property: opacity, color;

    &:hover {
      opacity: 0.8;
      color: red;
    }
  }
}
