@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;700&family=Lato:wght@400;900&display=swap');

/* Reset ------------ */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

:root {
  --bg: white;
  --p-color: #808080;
  --color-white: white;
  --color-black: black;
  --footer-color: #959595;
  --border: 1px solid #f0f0f0;
  --border-focus: 1px solid #fff;
  --box-shadow: 0 2px 10px #f0f0f0;
  --box-shadow-focus: 0 2px 20px #d3d3d3;
  --search-bar-color: #d3d3d3;
  --search-icon: #d3d3d3;
  --search-icon-focus: #d3d3d3;
  --opacity-search-placeholder: 1;
  /* --input-color: #808080; */
  --input-color: #b4b4b4;
  --input-placeholder-color: #d3d3d3;
  --response-color: #808080;
  --button-hover-opacity: 0.75;
  --cancel-search-icon: #d3d3d3;
  --border-error: 2px solid red;
  --empty-submit-color: #b4b4b4;
}

[data-theme='dark'] {
  --bg: black;
  --p-color: #d3d3d3;
  --color-white: black;
  --color-black: white;
  --footer-color: #bfbfbf;
  --border: 3px solid #3e3e3e;
  --border-focus: 3px solid white;
  --box-shadow: none;
  --box-shadow-focus: none;
  --search-bar-color: #bbbbbb;
  --search-icon: #3e3e3e;
  --search-icon-focus: white;
  --opacity-search-placeholder: 0.3;
  --input-color: white;
  --input-placeholder-color: #3e3e3e;
  --response-color: white;
  --button-hover-opacity: 0.8;
  --cancel-search-icon: #fff;
  --border-error: 2px solid red;
  --empty-submit-color: #b4b4b4;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  transition-duration: 500ms;
  transition-property: background-color;
  background-color: var(--bg);
}

p {
  color: var(--p-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
  margin-top: 150px;
}
