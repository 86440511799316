.search-container {
  position: relative;
  margin: 0 auto;
  margin-bottom: 60px;

  input[type='text'] {
    background-color: var(--color-white);
    padding: 18px 0 20px;
    padding-left: 19.5%;
    padding-right: 10.5%;
    width: 80%;
    margin: 15px 0 8px;
    border: var(--border);
    border-radius: 5px;
    font-size: 1.2rem;
    color: var(--input-color);
    box-sizing: border-box;
    box-shadow: var(--box-shadow);
    -webkit-appearance: none;
    transition-duration: 500ms;
    transition-property: box-shadow, border, background-color, color, visibility;

    &::placeholder {
      color: var(--input-placeholder-color);
      transition-duration: 500ms;
      transition-property: color;
    }

    &:focus {
      box-shadow: var(--box-shadow-focus);
      border: var(--border-focus);
      outline: none;
    }

    &.show-search-text {
      color: var(--input-color);
    }

    &.hide-search-text {
      color: var(--bg);
    }
  }

  i {
    &.search-icon {
      position: absolute;
      color: var(--search-icon);
      font-size: 2rem;
      left: 15%;
      bottom: 28%;
      transition-duration: 500ms;
      transition-property: color;
      z-index: 5;

      &.focus {
        color: var(--search-icon-focus);
      }
    }

    &.cancel-search-icon {
      position: absolute;
      cursor: pointer;
      color: var(--cancel-search-icon);
      font-size: 1rem;
      right: 15%;
      bottom: 37%;
      transition-duration: 500ms;
      transition-property: visibility, opacity;

      &:hover {
        opacity: 0.8;
      }

      &:active {
        // bottom: 36%;
        opacity: 1;
      }
    }
  }
}

@media only screen and (min-width: 1250px) {
  .search-container {
    input[type='text'] {
      padding: 21px 0 22px;
      padding-left: 21%;
      font-size: 1.5rem;
    }

    i {
      &.search-icon {
        font-size: 2.5rem;
        left: 15%;
        bottom: 25%;
      }

      &.cancel-search-icon {
        font-size: 1.2rem;
        right: 15%;
        bottom: 37%;
      }
    }
  }
}

@media only screen and (max-width: 465px) {
  .search-container {
    input[type='text'] {
      width: 90%;
    }

    i {
      &.search-icon {
        left: 10%;
      }

      &.cancel-search-icon {
        right: 10%;
      }
    }
  }
}

@media only screen and (max-width: 407px) {
  .search-container {
    input[type='text'] {
      width: 95%;
      padding-right: 12.5%;
    }

    i {
      &.search-icon {
        left: 8%;
      }

      &.cancel-search-icon {
        right: 8%;
      }
    }
  }
}

@media only screen and (max-width: 340px) {
  .search-container {
    input[type='text'] {
      width: 90%;
    }
    i {
      &.search-icon {
        left: 10%;
      }

      &.cancel-search-icon {
        right: 10%;
      }
    }
  }
}
